/*******************/
/* Base definition */
/*******************/
html {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: medium;
}

/**********/
/* Navbar */
/**********/
.nav-bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  background-color: rgb(204, 198, 193);
  margin: 0.4em;
  padding: 0.4rem;
  width: calc(100% - 0.8rem);
}

.nav-cell {
  display: table-cell;
}

.nav-button {
  background-color: rgb(75, 70, 65);
  border: none;
  color: white;
  margin-left: 0.4rem;
}

/**********/
/* Login */
/**********/
.login-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: baseline;
  align-content: space-between;
  margin: 0.4rem;
  padding: 0.4rem;
  width: calc(100% - 0.8rem);
}

.login-cell {
  padding: 0.4rem;
}

/*************/
/* List-Card */
/*************/
.list-info {
  display: flexbox;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 0.8rem);
}

/*************/
/* Task-Card */
/*************/
.task-card, .list-card {
  align-items: center;
  justify-content: space-between;
  background-color: rgb(236, 233, 230);
  margin: 0.4rem;
  width: calc(100% - 0.8rem);
}

.tasks {
  width: 100%;
}

.task-cell, .list-cell {
  display: table-cell;
  padding: 0.4em;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
}

.task-task {
  text-align: left;
  word-wrap: normal;
}

.task-card input, task-card textarea {
  width: 100%;
}

.task-button {
  display: table-cell;
  height: 2em;
  vertical-align: middle;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

/* Action button */

.task-actions, .task-action-cell {
  display: flex;
}


.task-actions {
  width: 50px;
  vertical-align: middle;
}

.task-action {
  display: table;   /* display: table */
  padding: 0.2em;
  justify-content: space-between;
  text-align: center;
  width: 100%;
}

/* Caption */
.tasks-caption {
  font-weight: bold;
  position: sticky;
  top: 0px;
  background-color: rgb(239, 230, 220);
}

.tasks-caption.task-action-cell {
  display: none;
}

.task-line-caption {
  border-width: 2px;
  width: 100%;
}

/**************************************************/
/* Small screen: Organize in rows, hide actions */
/**************************************************/
@media screen and (max-width: 650px) {
  .task-card {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .task-info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .task-info-main, .task-info-sub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .task-pos, .task-prio, .task-time, .task-actions {
    width: 15%;
  }

  .task-actions {
    vertical-align: middle;
  }

  .task-task {
    flex-grow: 8;
  }

  .task-status {
    flex-grow: 3;
  }

  .hide-on-small {
    display: none;
  }
}

/**************************************************************/
/* Medium screen: Organize in colums, but still hide actions */
/**************************************************************/

@media screen and (min-width: 650px) {
  .task-card {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .task-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  .task-info-main, .task-info-sub {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* justify-content: flex-start;*/
  }

  .task-task {
    /* width: 330px; */
    width: calc(70vw - 214px);
    }

  .task-pos, .task-prio, .task-time {
    width: calc(5.71vw + 22px);
  }

  .task-status {
    width: calc(11.43vw + 45px)
  }

  .hide-on-small {
    display: none;
  }
}


/* Large screens: Organize in colums, show actions */
@media screen and (min-width: 1000px) {
  .task-cell.task-action-cell {
    display: flex;
  }

  .task-card {
    display: flex;
    flex-direction: row;
  }

  .task-actions {
    display: None;
  }

  .task-action {
    flex-direction: row;
  }

  .task-action-cell {
    display: flex;
    flex-wrap: nowrap;
  }
}

